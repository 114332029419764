import React, { useState, useEffect } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { Pencil, Lightbulb, Laptop, Globe } from "lucide-react";

import logoBlack from "./assets/logoBlack.png";

const icons = [Lightbulb, Pencil, Laptop, Globe, "Logo"];

export default function AnimatedHero({ onAnimationComplete }) {
  const [showIntro, setShowIntro] = useState(true);
  const [currentIcon, setCurrentIcon] = useState(-1);
  const [showFinalState, setShowFinalState] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (currentIcon < icons.length - 2) {
        setCurrentIcon((prev) => prev + 1);
      } else if (currentIcon === icons.length - 2) {
        setCurrentIcon((prev) => prev + 1);
        setTimeout(() => {
          setShowFinalState(true);
          setTimeout(() => {
            setShowIntro(false);
            onAnimationComplete(); // Trigger the callback when animation is complete
          }, 1000); // Show final state for 2 seconds before fading out
        }, 200); // Reduced wait time to 200ms before showing final state
      }
    }, 400); // 400ms between each icon appearance

    return () => clearTimeout(timer);
  }, [currentIcon, onAnimationComplete]);

  return (
    <div className="bg-[#fff] min-h-dvh flex items-center justify-center">
      <AnimatePresence mode="wait">
        {showIntro && (
          <motion.div
            key="intro"
            initial={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.3 }}
            className="relative w-96 h-96 flex flex-col items-center justify-center"
          >
            {/* Center icons */}
            {icons.slice(0, -1).map((Icon, index) => (
              <motion.div
                key={`center-${index}`}
                initial={{ opacity: 0, scale: 0 }}
                animate={{
                  opacity: currentIcon === index ? 1 : 0,
                  scale: currentIcon === index ? 1 : 0,
                }}
                exit={{ opacity: 0, scale: 0 }}
                transition={{ duration: 0.2 }}
                className="absolute"
              >
                <Icon size={64} color="#000" />
              </motion.div>
            ))}

            {/* Final state with upper icon row and Logo */}
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: showFinalState ? 1 : 0 }}
              transition={{ duration: 0.3 }}
              className="absolute inset-0 flex flex-col items-center justify-center"
            >
              <div className="flex mb-8">
                {icons.slice(0, -1).map((Icon, index) => (
                  <div key={`final-${index}`} className="mx-4">
                    <Icon className="w-8 h-8 md:w-16 md:h-16" color="#000" />
                  </div>
                ))}
              </div>
              <motion.div
                className="relative"
                transition={{
                  duration: 2,
                  repeat: Infinity,
                  repeatType: "reverse",
                }}
              >
                <img
                  src={logoBlack}
                  alt="Your Logo"
                  className="h-8 sm:h-16 w-auto"
                />
              </motion.div>
            </motion.div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
}
