import React, { useState } from "react";
import { motion } from "framer-motion";
import { Check } from "lucide-react";

const monthlyFeatures = [
  "Professional designed website",
  "Mobile responsive",
  "Copywriting 1000 words SEO",
  "SSL",
  "Analytics",
  "Hosting",
  "Maintenance/Support",
  "Build Hours 1h per month",
];

const additionalFeatures = [
  { name: "Additional Copywriting (per 500 words)", price: 300 },
  { name: "Contact Form", price: 500 },
  { name: "Complex Animations", price: 1000 },
  { name: "Advanced Search Functionality", price: 1200 },
  { name: "Database Integration", price: 1500 },
  { name: "Booking System (Appointments)", price: 1800 },
  { name: "User Authentication (Login + Dashboard)", price: 2000 },
  { name: "E-commerce Integration", price: 3000 },
];

const PricingCard = ({ title, price, features }) => (
  <motion.div
    initial={{ opacity: 0, y: 20 }}
    animate={{ opacity: 1, y: 0 }}
    transition={{ duration: 0.5 }}
    className="bg-white p-6 rounded-lg shadow-lg flex flex-col h-full"
  >
    <h3 className="text-2xl font-bold text-[#172439] mb-4">{title}</h3>
    <ul className="flex-grow mb-8">
      {features.map((feature, index) => (
        <li key={index} className="flex items-center mb-2">
          <Check className="text-green-500 mr-2" size={20} />
          <span className="text-gray-700">{feature}</span>
        </li>
      ))}
    </ul>
    <div className="text-4xl font-bold text-indigo-500">
      {price} SEK
      <span className="text-lg text-gray-500 font-normal">/month</span>
    </div>
    <button className="mt-6 bg-indigo-500 text-white py-2 px-4 rounded hover:bg-indigo-700 transition-colors uppercase">
      Get Started
    </button>
  </motion.div>
);

const AdditionalFeaturesGrid = ({ onFeatureToggle, selectedFeatures }) => (
  <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
    {additionalFeatures.map((feature, index) => (
      <motion.div
        key={index}
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5, delay: index * 0.1 }}
        className={`bg-white p-4 rounded-lg shadow-md flex items-center space-x-4 ${
          selectedFeatures.includes(feature.name)
            ? "border-2 border-[#6366f1]"
            : ""
        }`}
      >
        <div className="flex items-center h-5 w-5 flex-shrink-0">
          <input
            type="checkbox"
            id={`feature-${index}`}
            name={feature.name}
            checked={selectedFeatures.includes(feature.name)}
            onChange={() => onFeatureToggle(feature.name)}
            className="form-checkbox h-5 w-5 text-indigo-600 rounded-full"
          />
        </div>
        <label
          htmlFor={`feature-${index}`}
          className="flex-grow cursor-pointer"
        >
          <span className="block text-sm font-medium text-gray-900">
            {feature.name}
          </span>
          <span className="block text-sm text-gray-500">
            {feature.price} SEK
          </span>
        </label>
      </motion.div>
    ))}
  </div>
);

export default function PricingSection() {
  const [selectedFeatures, setSelectedFeatures] = useState([]);

  const handleFeatureToggle = (featureName) => {
    setSelectedFeatures((prev) =>
      prev.includes(featureName)
        ? prev.filter((f) => f !== featureName)
        : [...prev, featureName]
    );
  };

  const totalPrice =
    2999 +
    selectedFeatures.reduce((sum, featureName) => {
      const feature = additionalFeatures.find((f) => f.name === featureName);
      return sum + (feature ? feature.price : 0);
    }, 0);

  return (
    <section className="bg-white pt-16 pb-4">
      <div className="container mx-auto px-4 pt-4 pb-16">
        <h2 className="text-4xl sm:text-5xl text-black mb-8 text-center uppercase font-medium tracking-wide">
          Pricing
        </h2>
        <div className="grid md:grid-cols-2 gap-8 mb-12">
          <PricingCard
            title="MONTHLY PACKAGE"
            price={totalPrice}
            features={monthlyFeatures}
          />
          <div>
            <h3 className="text-2xl font-bold text-[#172439] mb-4 uppercase">
              Additional Features
            </h3>
            <p className="text-gray-600 mb-4">
              Select additional features to enhance your package:
            </p>
            <AdditionalFeaturesGrid
              onFeatureToggle={handleFeatureToggle}
              selectedFeatures={selectedFeatures}
            />
          </div>
        </div>
      </div>
    </section>
  );
}
