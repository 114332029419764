import React from "react";
import { motion } from "framer-motion";
import { Code, PhoneCall, Clock } from "lucide-react";

const FeatureItem = ({ icon: Icon, text }) => (
  <div className="flex items-center space-x-4 p-4 border border-white/20 rounded-lg bg-white/10 backdrop-blur-sm">
    <div className="flex-shrink-0">
      <Icon className="w-6 h-6 text-white stroke-indigo-500" />
    </div>
    <p className="text-gray-100 text-lg">{text}</p>
  </div>
);

export default function OurMissionSection() {
  return (
    <section className="pt-16 pb-4 flex items-center bg-gradient-to-r from-slate-500 to-slate-300">
      <div className="container mx-auto px-4 pt-4 pb-16">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.6 }}
          className="max-w-4xl mx-auto"
        >
          <h2 className="text-4xl sm:text-5xl text-white mb-20 text-center uppercase font-medium tracking-wide">
            Our Mission
          </h2>
          <div className="space-y-8 text-gray-100 text-lg sm:text-xl leading-relaxed">
            <p>
              We specialize in creating unique, custom websites that perfectly
              align with your brand's needs. Our comprehensive package covers
              everything — from expert website design and SEO optimization to
              reliable hosting.
            </p>
            <p>
              Even if you're starting from scratch, we’ll handle all the
              essentials, including SEO-friendly copywriting and high-quality
              images, ensuring your site looks polished and professional from
              day one.
            </p>
            <p>You'll also have direct access to:</p>
            <div className="space-y-4 lg:space-y-0 lg:flex lg:space-x-4">
              <FeatureItem icon={Code} text="Dedicated developer" />
              <FeatureItem icon={PhoneCall} text="Email and phone support" />
              <FeatureItem
                icon={Clock}
                text="1hr build time every month for adjustments"
              />
            </div>
          </div>
        </motion.div>
      </div>
    </section>
  );
}
