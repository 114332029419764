import React, { useCallback, useEffect, useRef, useState } from "react";
import { AnimatePresence, motion } from "framer-motion";

import full from "./assets/full.webp";
import landingPage from "./assets/landingPage.webp";

const projects = [
  {
    id: 1,
    title: "E-commerce Platform",
    thumbnail: landingPage,
    fullImage: full,
    longDescription:
      "This e-commerce platform was built using React and Next.js, providing a seamless shopping experience. It features advanced product filtering, real-time search, user authentication, and integration with popular payment gateways. The backend is powered by Node.js and Express, with MongoDB as the database.",
  },
];

const ProjectCard = ({ project, isExpanded, onClick, forwardedRef }) => {
  const scrollRef = useRef(null);

  useEffect(() => {
    if (isExpanded && scrollRef.current) {
      const scrollElement = scrollRef.current;
      let animationId;

      const autoScroll = () => {
        if (
          scrollElement.scrollTop <
          scrollElement.scrollHeight - scrollElement.clientHeight
        ) {
          scrollElement.scrollTop += 2;
          animationId = requestAnimationFrame(autoScroll);
        }
      };

      const timeoutId = setTimeout(() => {
        animationId = requestAnimationFrame(autoScroll);
      }, 1000);

      return () => {
        clearTimeout(timeoutId);
        cancelAnimationFrame(animationId);
      };
    }
  }, [isExpanded]);

  return (
    <motion.div
      layout
      ref={forwardedRef}
      className={`bg-white shadow-lg overflow-hidden cursor-pointer ${
        isExpanded ? "col-span-full" : ""
      }`}
      onClick={() => onClick(project.id)}
    >
      {isExpanded ? (
        <div className="flex flex-col lg:flex-row h-[70vh]">
          <div
            className="w-full lg:w-[60%] h-full overflow-y-auto"
            ref={scrollRef}
          >
            <img
              src={project.fullImage}
              alt={`${project.title} full view`}
              className="w-full h-auto object-contain"
            />
          </div>
          <div className="w-full lg:w-[40%] p-4 overflow-y-auto">
            <h3 className="text-xl font-semibold text-gray-800 mb-6">
              {project.title}
            </h3>
            <p className="text-gray-600">{project.longDescription}</p>
          </div>
        </div>
      ) : (
        <div className="relative group">
          <div className="relative">
            <img
              src={project.thumbnail}
              alt={project.title}
              className="w-full h-auto object-contain brightness-75 group-hover:brightness-100 transition-all duration-300"
            />
            <div className="absolute inset-0 bg-gradient-to-t from-slate-500 to-slate-500 opacity-50 group-hover:opacity-0 transition-opacity duration-300 "></div>
          </div>
          <div className="absolute inset-x-0 bottom-0 bg-black bg-opacity-60 p-4">
            <h3 className="text-xl font-semibold text-white text-center uppercase">
              {project.title}
            </h3>
          </div>
          <div className="absolute top-4 right-4 text-white opacity-100 group-hover:opacity-0 transition-opacity duration-300">
            <span className="bg-black bg-opacity-60 px-3 py-2 rounded-md">
              View Project
            </span>
          </div>
        </div>
      )}
    </motion.div>
  );
};

export default function ProjectsSection() {
  const [expandedId, setExpandedId] = useState(null);
  const projectRefs = useRef({});

  const toggleExpand = useCallback((id) => {
    setExpandedId((prevId) => (prevId === id ? null : id));
  }, []);

  useEffect(() => {
    if (expandedId !== null && projectRefs.current[expandedId]) {
      const scrollToProject = () => {
        projectRefs.current[expandedId].scrollIntoView({
          behavior: "smooth",
          block: "center",
        });
      };
      // Delay scrolling to allow for layout animation to complete
      setTimeout(scrollToProject, 300);
    }
  }, [expandedId]);

  return (
    <section className="bg-white pt-16 pb-4">
      <div className="container mx-auto px-4 pt-4 pb-16">
        <h2 className="text-4xl sm:text-5xl text-black mb-20 text-center uppercase font-medium tracking-wide">
          Our Projects
        </h2>

        <motion.div layout className="grid gap-8 grid-cols-1 md:grid-cols-2">
          <AnimatePresence>
            {projects.map((project) => (
              <ProjectCard
                key={project.id}
                project={project}
                isExpanded={expandedId === project.id}
                onClick={toggleExpand}
                forwardedRef={(el) => (projectRefs.current[project.id] = el)}
              />
            ))}
          </AnimatePresence>
        </motion.div>
      </div>
    </section>
  );
}
