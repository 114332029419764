import React from "react";
import Navbar from "./REUSE_NAV";
import Footer from "./Footer";

function About() {
  return (
    <div className="min-h-screen flex flex-col">
      <Navbar />
      <div className="flex-grow container mx-auto px-4 py-16">
        <h2 className="text-4xl font-bold mb-8 text-center">About Us</h2>
        <p className="text-lg text-gray-700 mb-4">
          Welcome to Aditra! We are dedicated to providing the best digital
          solutions tailored to your business needs.
        </p>
        <p className="text-lg text-gray-700 mb-4">
          Our team consists of passionate professionals with expertise in web
          development, design, and digital marketing.
        </p>
        <p className="text-lg text-gray-700">
          We aim to help businesses succeed online by delivering high-quality,
          custom-made websites and strategies that drive results.
        </p>
      </div>
      <Footer />
    </div>
  );
}

export default About;
