import React from "react";
import Navbar from "./REUSE_NAV";
import Footer from "./Footer";

function PrivacyPolicy() {
  return (
    <div className="min-h-screen flex flex-col">
      <Navbar />
      <div className="flex-grow container mx-auto px-4 py-16">
        <h2 className="text-4xl font-bold mb-8 text-center uppercase">
          Privacy Policy
        </h2>

        <p className="text-lg mb-4">**Effective Date:** 2024-10-28</p>

        <p className="mb-4">
          Welcome to Aditra ("we," "our," "us"). We are committed to protecting
          your privacy. This Privacy Policy explains how we collect, use, and
          protect information about you when you use our website and services.
        </p>

        <h3 className="text-2xl font-semibold mt-8 mb-4">
          1. Information We Collect
        </h3>

        <p className="mb-2">
          We collect information to provide better services to our users. The
          information we collect depends on how you interact with our website.
        </p>

        <h4 className="text-xl font-semibold mt-6 mb-2">
          a. Information You Provide to Us
        </h4>

        <ul className="list-disc list-inside mb-4">
          <li>
            **Contact Information**: Name, email, phone number, and any
            additional information you provide.
          </li>
          <li>
            **Project Details**: Information about your business and project
            requirements.
          </li>
        </ul>

        <h4 className="text-xl font-semibold mt-6 mb-2">
          b. Information We Automatically Collect
        </h4>

        <ul className="list-disc list-inside mb-4">
          <li>
            **Usage Information**: IP address, browser type, pages visited, and
            time spent on the site.
          </li>
          <li>
            **Cookies**: Used to remember preferences, understand site usage,
            and improve performance.
          </li>
        </ul>

        <h3 className="text-2xl font-semibold mt-8 mb-4">
          2. How We Use Your Information
        </h3>

        <ul className="list-disc list-inside mb-4">
          <li>**Provide Services**: Process inquiries and fulfill requests.</li>
          <li>
            **Improve Our Website**: Analyze site usage for a better experience.
          </li>
          <li>**Communicate with You**: Send updates and notifications.</li>
          <li>
            **Security and Compliance**: Detect fraud and comply with laws.
          </li>
        </ul>

        <h3 className="text-2xl font-semibold mt-8 mb-4">
          3. Sharing Your Information
        </h3>

        <p className="mb-4">
          We do not share, sell, or rent your information to third parties,
          except as follows:
        </p>
        <ul className="list-disc list-inside mb-4">
          <li>
            **Service Providers**: Trusted third parties who assist in providing
            services.
          </li>
          <li>
            **Legal Requirements**: Disclosure if required by law or to protect
            our rights.
          </li>
        </ul>

        <h3 className="text-2xl font-semibold mt-8 mb-4">
          4. Your Rights and Choices
        </h3>

        <ul className="list-disc list-inside mb-4">
          <li>
            **Access**: Request a copy of the information we hold about you.
          </li>
          <li>**Correction**: Update inaccurate information.</li>
          <li>**Deletion**: Request deletion in certain circumstances.</li>
          <li>**Opt-Out**: Unsubscribe from marketing emails.</li>
        </ul>

        <h3 className="text-2xl font-semibold mt-8 mb-4">
          5. Security of Your Information
        </h3>

        <p className="mb-4">
          We use industry-standard security measures to protect your
          information. However, no method is 100% secure, and we cannot
          guarantee absolute security.
        </p>

        <h3 className="text-2xl font-semibold mt-8 mb-4">
          6. Cookies and Tracking Technologies
        </h3>

        <p className="mb-4">
          We use cookies to enhance your experience. You can control cookies
          through browser settings, but disabling them may limit functionality.
        </p>

        <h3 className="text-2xl font-semibold mt-8 mb-4">
          7. Third-Party Links
        </h3>

        <p className="mb-4">
          Our website may contain links to third-party sites. We encourage you
          to review their privacy policies as we are not responsible for their
          practices.
        </p>

        <h3 className="text-2xl font-semibold mt-8 mb-4">
          8. Changes to This Privacy Policy
        </h3>

        <p className="mb-4">
          We may update this Privacy Policy periodically. Changes will be posted
          on this page with an updated effective date. We encourage you to
          review it regularly.
        </p>

        <h3 className="text-2xl font-semibold mt-8 mb-4">9. Contact Us</h3>

        <p className="mb-4">
          If you have any questions about this Privacy Policy, please contact us
          at:
        </p>
        <ul className="list-inside mb-4">
          <li>**Email**: hello@aditra.com</li>
          {/* <li>**Phone**: [Your Phone Number]</li>
          <li>**Address**: [Your Company Address]</li> */}
        </ul>
      </div>
      <Footer />
    </div>
  );
}

export default PrivacyPolicy;
