"use client";

import { useState, useEffect, useCallback } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { ChevronUp, ChevronDown } from "lucide-react";

const caseStudies = [
  {
    id: 1,
    title: "Optimizing E-commerce for SvenskMode (Concept)",
    description:
      "Redesigned the fashion brand's platform to boost mobile engagement and conversions with a focus on UX.",
    quote:
      "The redesign aimed at a smooth mobile shopping experience, making product discovery and checkout easier.",
    author: "Alexander Magnusson, UX Designer",
  },
  {
    id: 2,
    title: "Revamping the UI for SvenskaBilar (Personal Project)",
    description:
      "Reimagined the automotive website's UI to simplify navigation and improve the browsing experience.",
    quote:
      "The new interface aimed to minimize user friction for easier vehicle browsing and inquiries.",
    author: "Erik Johansson, UI/UX Designer",
  },
  {
    id: 3,
    title: "Streamlining User Flows for DeutscheTech (Concept)",
    description:
      "Simplified the SaaS platform's user experience to improve onboarding and reduce drop-off rates.",
    quote:
      "The redesign focused on lowering cognitive load for smoother user onboarding.",
    author: "Andreas Busch, UX/UI Designer",
  },
  {
    id: 4,
    title: "Enhancing Accessibility for ZN Retail (Concept)",
    description:
      "Improved accessibility for a UK-based retailer by enhancing navigation and contrast.",
    quote:
      "The updates ensured a seamless, inclusive shopping experience across all devices.",
    author: "Emily Cartwright, Frontend Developer",
  },
];

export default function CaseStudiesSection() {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [progress, setProgress] = useState(0);

  const nextCase = useCallback(() => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % caseStudies.length);
    setProgress(0);
  }, []);

  const prevCase = useCallback(() => {
    setCurrentIndex(
      (prevIndex) => (prevIndex - 1 + caseStudies.length) % caseStudies.length
    );
    setProgress(0);
  }, []);

  useEffect(() => {
    const timer = setInterval(() => {
      setProgress((prevProgress) => {
        if (prevProgress >= 100) {
          nextCase();
          return 0;
        }
        return prevProgress + 0.5;
      });
    }, 50); // Update progress every 50ms for smooth animation

    return () => clearInterval(timer);
  }, [nextCase]);

  return (
    <section className="py-16 flex items-center bg-gradient-to-r from-slate-300 to-slate-500 ">
      <div className="container mx-auto px-4 pt-4 pb-16">
        <h2 className="text-4xl sm:text-5xl text-white mb-20 text-center uppercase font-medium tracking-wide">
          Case Studies
        </h2>
        <div className="relative">
          <AnimatePresence mode="wait">
            <motion.div
              key={currentIndex}
              initial={{ opacity: 0, y: 50 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: -50 }}
              transition={{ duration: 0.5 }}
              className="bg-white rounded-lg shadow-lg overflow-hidden h-[500px] md:h-[350px] lg:h-[350px] xl:h-[350px] 2xl:h-[350px]" // Fixed heights up to 2xl, auto above
              style={{ overflowY: "auto" }}
            >
              <div className="flex flex-col h-full md:flex-row">
                <div className="md:w-1/2 p-8 flex flex-col justify-center flex-1">
                  <div className="text-4xl sm:text-6xl font-bold text-indigo-500 mb-4">
                    #{caseStudies[currentIndex].id}
                  </div>
                  <h3 className="text-2xl font-semibold mb-4">
                    {caseStudies[currentIndex].title}
                  </h3>
                  <p className="text-gray-600">
                    {caseStudies[currentIndex].description}
                  </p>
                </div>
                <div className="md:w-1/2 bg-gray-100 p-8 flex flex-col justify-center flex-1">
                  <blockquote className="text-lg italic text-gray-800 mb-4">
                    "{caseStudies[currentIndex].quote}"
                  </blockquote>
                  <cite className="text-right text-gray-600">
                    - {caseStudies[currentIndex].author}
                  </cite>
                </div>
              </div>
            </motion.div>
          </AnimatePresence>

          <button
            onClick={prevCase}
            className="absolute left-1/2 -top-12 transform -translate-x-1/2 bg-white bg-opacity-80 backdrop-blur-lg rounded-full p-2 shadow-lg hover:bg-opacity-100 transition-all duration-300 focus:outline-none focus:ring-2 focus:ring-gray-400 focus:ring-opacity-50"
            aria-label="Previous case study"
          >
            <ChevronUp className="w-6 h-6 text-gray-800" />
          </button>

          <button
            onClick={nextCase}
            className="absolute left-1/2 -bottom-12 transform -translate-x-1/2 bg-white bg-opacity-80 backdrop-blur-lg rounded-full p-2 shadow-lg hover:bg-opacity-100 transition-all duration-300 focus:outline-none focus:ring-2 focus:ring-gray-400 focus:ring-opacity-50"
            aria-label="Next case study"
          >
            <ChevronDown className="w-6 h-6 text-gray-800" />
          </button>

          <div className="absolute bottom-0 left-0 right-0 h-1 bg-gray-200">
            <motion.div
              className="h-full bg-indigo-500"
              initial={{ width: 0 }}
              animate={{ width: `${progress}%` }}
              transition={{ duration: 0.05, ease: "linear" }}
            />
          </div>
        </div>
      </div>
    </section>
  );
}
