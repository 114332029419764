import React, { useState } from "react";
import { Menu, X } from "lucide-react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import logo from "./assets/logo.png";

export default function Navbar({ scrollToSection, refs }) {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();

  const handleScrollToContact = () => {
    if (location.pathname === "/") {
      if (refs && refs.contactFormRef && refs.contactFormRef.current) {
        scrollToSection(refs.contactFormRef);
      } else {
        console.warn("Contact section not available.");
      }
    } else {
      navigate("/");
      setTimeout(() => {
        if (refs && refs.contactFormRef && refs.contactFormRef.current) {
          scrollToSection(refs.contactFormRef);
        }
      }, 100);
    }
  };

  return (
    <nav className="bg-[#000] text-white">
      <div className="max-w-full mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex items-center justify-between h-16 sm:h-20">
          <div className="flex items-center">
            <Link to="/" className="flex-shrink-0">
              <img
                className="h-5 sm:h-8 w-auto"
                src={logo}
                alt="Company Logo"
              />
            </Link>
          </div>
          <div className="hidden md:block">
            <div className="ml-10 flex items-baseline space-x-4">
              <Link
                to="/about"
                className="text-[#ccc] hover:text-white transition-colors px-3 py-2 rounded-md text-sm font-medium"
              >
                About Us
              </Link>
              <button
                onClick={handleScrollToContact}
                className="relative inline-flex items-center justify-center p-0.5 overflow-hidden text-sm font-medium text-[#fff] group border border-indigo-500 uppercase hover:bg-indigo-700 hover:text-[#fff] transition-all duration-300 shadow-cta-glow2 rounded-md"
              >
                <span className="relative px-5 py-2.5 transition-all ease-in duration-75">
                  Contact Us
                </span>
              </button>
            </div>
          </div>
          <div className="-mr-2 flex md:hidden">
            <button
              onClick={() => setIsMenuOpen(!isMenuOpen)}
              className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-white hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white"
            >
              <span className="sr-only">Open main menu</span>
              {isMenuOpen ? <X size={24} /> : <Menu size={24} />}
            </button>
          </div>
        </div>
      </div>

      {isMenuOpen && (
        <div className="md:hidden">
          <div className="px-2 pt-2 pb-3 space-y-1 sm:px-3">
            <Link
              to="/about"
              className="text-[#ccc] hover:text-white transition-colors block px-3 py-2 rounded-md text-base font-medium"
            >
              About Us
            </Link>
            <button
              onClick={() => {
                setIsMenuOpen(false);
                handleScrollToContact();
              }}
              className="relative inline-flex items-center justify-center p-0.5 overflow-hidden text-sm font-medium text-[#fff] group border border-indigo-500 uppercase hover:bg-indigo-700 hover:text-[#fff] transition-all duration-300 shadow-cta-glow2 rounded-md w-full mt-2"
            >
              <span className="relative px-5 py-2.5 transition-all ease-in duration-75 w-full">
                Contact Us
              </span>
            </button>
          </div>
        </div>
      )}
    </nav>
  );
}
