import React, { useRef } from "react";
import Navbar from "./REUSE_NAV";
import HeroSection from "./REUSE_HeroSection";
import OurMissionSection from "./OurMission";
import ProjectsSection from "./ProjectsSection";
import CaseStudies from "./CaseStudies";
import PricingSection from "./Pricing";
import ContactForm from "./ContactForm";
import Footer from "./Footer";

function LandingPage() {
  const ourMissionRef = useRef(null);
  const projectsRef = useRef(null);
  const caseStudiesRef = useRef(null);
  const pricingSectionRef = useRef(null);
  const contactFormRef = useRef(null);

  const scrollToSection = (ref) => {
    ref.current.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <>
      <Navbar scrollToSection={scrollToSection} refs={{ contactFormRef }} />
      <HeroSection
        scrollToSection={scrollToSection}
        refs={{ ourMissionRef, contactFormRef }}
      />
      <div ref={ourMissionRef}>
        <OurMissionSection />
      </div>
      <div ref={projectsRef}>
        <ProjectsSection />
      </div>
      <div ref={caseStudiesRef}>
        <CaseStudies />
      </div>
      <div ref={pricingSectionRef}></div>
      <PricingSection />
      <div ref={contactFormRef}>
        <ContactForm />
      </div>
      <Footer
        scrollToSection={scrollToSection}
        refs={{
          ourMissionRef,
          projectsRef,
          caseStudiesRef,
          pricingSectionRef,
          contactFormRef,
        }}
      />
    </>
  );
}

export default LandingPage;
