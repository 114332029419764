import React from "react";
import Navbar from "./REUSE_NAV";
import Footer from "./Footer";

function TermsOfService() {
  return (
    <div className="min-h-screen flex flex-col">
      <Navbar />
      <div className="flex-grow container mx-auto px-4 py-16">
        <h2 className="text-4xl font-bold mb-8 text-center">
          Terms of Service
        </h2>

        <p className="text-lg mb-4">**Effective Date:** 2024-10-28</p>

        <p className="mb-4">
          Welcome to Aditra ("we," "our," or "us"). By using our services, you
          ("Client," "you," or "your") agree to these Terms of Service. These
          Terms govern your use of our website and subscription-based services.
          Please read them carefully.
        </p>

        <h3 className="text-2xl font-semibold mt-8 mb-4">
          1. Scope of Services
        </h3>

        <p className="mb-4">
          We offer web development, design, and related digital services on a
          subscription basis. Each service includes a scope of work outlined in
          the service agreement, which is subject to our final discretion in
          development, design, and service decisions.
        </p>

        <h3 className="text-2xl font-semibold mt-8 mb-4">
          2. Subscription-Based Model
        </h3>

        <p className="mb-4">
          Our services are provided on a monthly subscription basis. You agree
          to pay the subscription fee as specified in the service agreement for
          the duration of the agreed term. Each subscription period renews
          automatically until canceled by you or us in accordance with these
          Terms.
        </p>

        <h3 className="text-2xl font-semibold mt-8 mb-4">3. Payment Terms</h3>

        <ul className="list-disc list-inside mb-4">
          <li>
            **Monthly Payments**: Subscription fees are due at the beginning of
            each monthly billing cycle. Payments are non-refundable, except as
            otherwise provided in these Terms.
          </li>
          <li>
            **Full Payment Option**: You may choose to pay the remaining
            subscription balance in full at any time. This will complete the
            service term and remove further monthly charges.
          </li>
          <li>
            **Late Payments**: If payment is not received on time, we reserve
            the right to suspend or terminate services until payment is made.
          </li>
        </ul>

        <h3 className="text-2xl font-semibold mt-8 mb-4">
          4. Client Responsibilities
        </h3>

        <ul className="list-disc list-inside mb-4">
          <li>
            Provide all necessary information and materials required for the
            services.
          </li>
          <li>Respond to communications and requests in a timely manner.</li>
          <li>
            Ensure that all content provided to us does not violate any laws or
            third-party rights.
          </li>
        </ul>

        <h3 className="text-2xl font-semibold mt-8 mb-4">
          5. Our Rights and Discretion
        </h3>

        <p className="mb-4">
          We retain the final say in all development, design, and
          service-related decisions to ensure quality and consistency. This
          includes, but is not limited to, decisions on layout, functionality,
          and design elements. While we welcome client feedback, we reserve the
          right to make the final decisions.
        </p>

        <h3 className="text-2xl font-semibold mt-8 mb-4">
          6. Modifications and Updates
        </h3>

        <p className="mb-4">
          We may modify or update these Terms from time to time. If significant
          changes are made, we will notify you via email or through our website.
          Continued use of our services after such notice will constitute your
          acceptance of the updated Terms.
        </p>

        <h3 className="text-2xl font-semibold mt-8 mb-4">
          7. Termination and Cancellation
        </h3>

        <ul className="list-disc list-inside mb-4">
          <li>
            **Client-Initiated Cancellation**: You may cancel your subscription
            at any time by notifying us in writing. Cancellations will take
            effect at the end of the current billing period.
          </li>
          <li>
            **Termination by Us**: We reserve the right to terminate or suspend
            services at any time if you violate these Terms, fail to make
            payments, or if we determine, in our sole discretion, that service
            continuation is no longer feasible.
          </li>
          <li>
            **Refunds**: If services are terminated by us for reasons unrelated
            to breach or non-payment, a pro-rated refund may be issued for
            unused service time.
          </li>
        </ul>

        <h3 className="text-2xl font-semibold mt-8 mb-4">
          8. Intellectual Property
        </h3>

        <p className="mb-4">
          All intellectual property rights in the services, designs, and
          materials provided remain with us unless otherwise agreed in writing.
          You are granted a limited, non-transferable license to use the final
          product solely for its intended purpose.
        </p>

        <h3 className="text-2xl font-semibold mt-8 mb-4">
          9. Limitation of Liability
        </h3>

        <p className="mb-4">
          We are not liable for any indirect, incidental, or consequential
          damages resulting from the use or inability to use our services. Our
          liability is limited to the amount paid for the services rendered
          during the most recent billing cycle.
        </p>

        <h3 className="text-2xl font-semibold mt-8 mb-4">10. Governing Law</h3>

        <p className="mb-4">
          These Terms are governed by and construed in accordance with the laws
          of [Your Country/State]. Any disputes arising from these Terms or the
          services provided shall be resolved in the courts of [Your
          Jurisdiction].
        </p>

        <h3 className="text-2xl font-semibold mt-8 mb-4">
          11. Contact Information
        </h3>

        <p className="mb-4">
          For any questions about these Terms or our services, please contact
          us:
        </p>

        <ul className="list-inside mb-4">
          <li>**Email**: hello@aditra.com</li>
          {/* <li>**Phone**: [Your Phone Number]</li>
          <li>**Address**: [Your Company Address]</li> */}
        </ul>
      </div>
      <Footer />
    </div>
  );
}

export default TermsOfService;
