import { useEffect, useRef, useState } from "react";
import { motion } from "framer-motion";

import "./HeroSection.css";

import logo1 from "./assets/CompanyLogos/1.png";
import logo2 from "./assets/CompanyLogos/2.png";
import logo3 from "./assets/CompanyLogos/3.png";
import logo4 from "./assets/CompanyLogos/4.png";
import logo5 from "./assets/CompanyLogos/5.png";
import logo6 from "./assets/CompanyLogos/6.png";
import logo7 from "./assets/CompanyLogos/7.png";
import logo8 from "./assets/CompanyLogos/8.png";

const LineAnimation = () => {
  const pathRef1 = useRef(null);
  const pathRef2 = useRef(null);

  const [pathLength1, setPathLength1] = useState(0);
  const [pathLength2, setPathLength2] = useState(0);

  useEffect(() => {
    const path1 = pathRef1.current;
    const path2 = pathRef2.current;

    if (path1 && path2) {
      const length1 = path1.getTotalLength();
      const length2 = path2.getTotalLength();
      setPathLength1(length1);
      setPathLength2(length2);
    }
  }, []);

  return (
    <svg
      className="absolute bottom-20 sm:bottom-48 min-[1090px]:bottom-52 left-0 w-full h-48"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 200 100"
      preserveAspectRatio="none"
    >
      <motion.path
        ref={pathRef1}
        id="zigzag-path-1"
        key={Math.random()}
        d="M0 90
         L 10 85
         L 20 90
         L 30 80
         L 40 85
         L 50 82
         L 60 90
         L 70 85
         L 80 88
         L 90 80
         L 100 85
         L 110 90
         L 120 85
         L 130 90
         L 140 90"
        fill="none"
        stroke="#000"
        strokeWidth="0.5"
        strokeDasharray={pathLength1}
        strokeDashoffset={pathLength1}
        animate={{ strokeDashoffset: 0 }}
        transition={{ duration: 1, ease: "linear", delay: 1 }}
      />

      <motion.path
        ref={pathRef2}
        id="zigzag-path-2"
        key={Math.random()}
        d="M 140 90
   L 145 85
   L 150 80
   L 155 75
   L 160 70
   L 165 65
   L 170 60
   L 175 55
   L 180 50
   L 185 45
   L 190 40
   L 195 35
   L 200 30
   L 205 25
   L 210 20
   L 215 15
   L 220 10
   L 225 5
   L 230 0
   L 235 -5
   L 240 -10
   L 245 -15
   L 250 -20"
        fill="none"
        stroke="#6366f1"
        strokeWidth="1"
        strokeDasharray={pathLength2}
        strokeDashoffset={pathLength2}
        animate={{ strokeDashoffset: 0 }}
        transition={{ duration: 1, ease: "linear", delay: 2 }}
      />
    </svg>
  );
};

const LogoStrip = () => {
  const logos = [
    { id: 1, src: logo1, alt: "Company 1" },
    { id: 2, src: logo2, alt: "Company 2" },
    { id: 3, src: logo3, alt: "Company 3" },
    { id: 4, src: logo4, alt: "Company 4" },
    { id: 5, src: logo5, alt: "Company 5" },
    { id: 6, src: logo6, alt: "Company 6" },
    { id: 7, src: logo7, alt: "Company 7" },
    { id: 8, src: logo8, alt: "Company 8" },
  ];

  return (
    <div className="bg-transparent overflow-hidden pb-8">
      <div className="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8 ">
        {/* <h2 className="text-center text-2xl font-semibold text-[#555] mb-10">
          Trusted by Industry Leaders
        </h2> */}
        <div className="relative">
          <motion.div
            className="flex space-x-12"
            animate={{
              x: [0, -1920],
            }}
            transition={{
              x: {
                repeat: Infinity,
                repeatType: "loop",
                duration: 30,
                ease: "linear",
              },
            }}
          >
            {[...logos, ...logos].map((logo) => (
              <div
                key={`${logo.id}-${Math.random()}`}
                className="flex-shrink-0 w-[150px] text-[#000]"
              >
                <img
                  src={logo.src}
                  alt={logo.alt}
                  className="h-12 w-auto object-contain"
                />
              </div>
            ))}
          </motion.div>
        </div>
      </div>
    </div>
  );
};

export default function HeroSection({ scrollToSection, refs }) {
  return (
    <div className="relative bg-pattern bg-[#fff] min-h-[calc(100svh-64px)] sm:min-h-[calc(100svh-80px)] flex flex-col justify-between overflow-hidden">
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.3 }}
        className="text-center px-4 z-10 mt-8 min-[450px]:mt-32 tall-hero-margin"
      >
        <h2 className="text-sm md:text-lg font-bold mb-4 text-[#222] uppercase tracking-wider">
          CUSTOM SOLUTIONS, HANDCRAFTED WEBSITES
        </h2>
        <h1 className="text-3xl md:text-5xl font-bold mb-6 text-black">
          EXPERT WEB AGENCY FOR <span className="text-indigo-500">GROWING</span>{" "}
          BRANDS
        </h1>
        <h2 className="text-lg md:text-2xl mb-10 min-[450px]:mb-20 text-[#111] max-w-3xl mx-auto">
          The complete website package, including a professional design,
          hosting, and SEO, all for{" "}
          <span className="text-transparent bg-clip-text bg-indigo-500 animate-pulse transition-colors duration-1000 ease-in-out">
            2999 SEK
          </span>{" "}
          per month.
        </h2>
        <div className="flex flex-col items-center space-y-4 sm:flex-row sm:space-y-0 sm:space-x-6 sm:justify-center">
          <button
            onClick={() => scrollToSection(refs.ourMissionRef)}
            className="bg-[#fff] border-[#555] border-2 hover:bg-[#eee] text-[#555] font-bold py-3 px-8 sm:px-10 text-lg uppercase transition duration-300 w-[200px] sm:w-auto rounded-md"
          >
            LEARN MORE
          </button>
          <button
            onClick={() => scrollToSection(refs.contactFormRef)}
            className="hover:bg-indigo-700 text-white font-bold py-3 px-8 sm:px-10 text-lg uppercase border-2 border-indigo-500 transition duration-300 bg-[#000] w-[200px] sm:w-auto shadow-cta-glow2 rounded-md"
          >
            CONTACT US
          </button>
        </div>
      </motion.div>
      <LineAnimation />
      <LogoStrip />
    </div>
  );
}
