import { motion } from "framer-motion";
import { Facebook, Twitter, Instagram, Linkedin, Github } from "lucide-react";
import logo from "./assets/logo.png";
import { Link, useLocation, useNavigate } from "react-router-dom";

const socialLinks = [
  { name: "Facebook", icon: Facebook, href: "#" },
  { name: "Twitter", icon: Twitter, href: "#" },
  { name: "Instagram", icon: Instagram, href: "#" },
  { name: "LinkedIn", icon: Linkedin, href: "#" },
  { name: "GitHub", icon: Github, href: "#" },
];

const footerLinks = [
  { name: "Our Mission", refName: "ourMissionRef" },
  { name: "Projects", refName: "projectsRef" },
  { name: "Case Studies", refName: "caseStudiesRef" },
  { name: "Pricing", refName: "pricingSectionRef" },
  { name: "About Us", to: "/about" },
  { name: "Privacy Policy", to: "/privacy-policy" },
  { name: "Terms of Service", to: "/terms-of-service" },
];

export default function Footer({ scrollToSection, refs }) {
  const location = useLocation();
  const navigate = useNavigate();

  const handleScroll = (ref) => {
    if (location.pathname === "/") {
      if (refs && ref && refs[ref]) {
        scrollToSection(refs[ref]);
      } else {
        console.warn("Section not available on this page.");
      }
    } else {
      navigate("/");
      setTimeout(() => {
        if (refs && ref && refs[ref]) scrollToSection(refs[ref]);
      }, 100);
    }
  };

  return (
    <footer className="bg-black text-white">
      <div className="max-w-5xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
        <div className="grid grid-cols-1 md:grid-cols-3 gap-20">
          <div className="space-y-6">
            <motion.img
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5 }}
              src={logo}
              alt="Company Logo"
              className="h-6"
            />
            <p className="text-sm text-white">
              Empowering businesses with cutting-edge technology solutions. We
              transform ideas into reality.
            </p>
            <div className="flex space-x-4">
              {socialLinks.map((social, index) => (
                <motion.a
                  key={social.name}
                  href={social.href}
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.5, delay: index * 0.1 }}
                  className="hover:text-gray-400 transition-colors duration-200"
                >
                  <social.icon className="h-6 w-6" />
                  <span className="sr-only">{social.name}</span>
                </motion.a>
              ))}
            </div>
          </div>

          <div className="md:col-span-2 grid grid-cols-2 gap-4">
            <div className="space-y-4">
              <h3 className="text-lg font-semibold">Quick Links</h3>
              <ul className="space-y-2">
                {footerLinks.slice(0, 4).map((link, index) => (
                  <motion.li
                    key={link.name}
                    initial={{ opacity: 0, x: -20 }}
                    animate={{ opacity: 1, x: 0 }}
                    transition={{ duration: 0.5, delay: index * 0.1 }}
                  >
                    <button
                      onClick={() => handleScroll(link.refName)}
                      className="text-sm hover:text-gray-400 transition-colors duration-200"
                    >
                      {link.name}
                    </button>
                  </motion.li>
                ))}
              </ul>
            </div>
            <div className="space-y-4">
              <h3 className="text-lg font-semibold">&nbsp;</h3>
              <ul className="space-y-2">
                {footerLinks.slice(4).map((link, index) => (
                  <motion.li
                    key={link.name}
                    initial={{ opacity: 0, x: -20 }}
                    animate={{ opacity: 1, x: 0 }}
                    transition={{ duration: 0.5, delay: (index + 4) * 0.1 }}
                  >
                    <Link
                      to={link.to}
                      className="text-sm hover:text-gray-400 transition-colors duration-200"
                    >
                      {link.name}
                    </Link>
                  </motion.li>
                ))}
              </ul>
            </div>
          </div>
        </div>

        <div className="mt-12 pt-8 border-t border-indigo-500 text-center text-sm text-white">
          <p>&copy; {new Date().getFullYear()} Aditra. All rights reserved.</p>
        </div>
      </div>
    </footer>
  );
}
